import { Link, useParams } from '@remix-run/react';

import { apiService } from '../../services/api-service';
import { type Promotion, PromotionStatus } from '../../types/promotion';
import { type Action, ActionSheet } from '../ActionSheet';
import { PauseIcon } from '../icons/PauseIcon';
import { PlayIcon } from '../icons/PlayIcon';
import { useGlobalPromotion, usePromotions } from './hooks';

function OrganizationActionSheet({
  promotion,
  onPause,
  onResume,
}: {
  promotion: Promotion;
  onPause: (promotionId: string) => void;
  onResume: (promotionId: string) => void;
}): JSX.Element {
  const actions: Action<'pause' | 'resume'>[] = [];

  if (promotion.status === PromotionStatus.Active) {
    actions.push({
      kind: 'button',
      key: 'pause',
      icon: <PauseIcon />,
      text: 'Pause Messages',
      className: 'text-white',
      onClick: () => {
        onPause(promotion.id);
      },
    });
  } else {
    actions.push({
      kind: 'button',
      key: 'resume',
      icon: <PlayIcon />,
      text: 'Resume Message',
      className: 'text-white',
      onClick: () => {
        onResume(promotion.id);
      },
    });
  }

  return <ActionSheet actions={actions} />;
}

export const PromotionList = (): JSX.Element => {
  const { id } = useParams<'id'>();

  const { data: globalPromotion } = useGlobalPromotion(id);
  const { data: promotions, mutate } = usePromotions({
    globalPromotionId: id,
  });

  const handlePause = async (promotionId: string) => {
    await apiService.promotion.pausePromotion(promotionId);
    mutate();
  };

  const handleResume = async (promotionId: string) => {
    await apiService.promotion.resumePromotion(promotionId);
    mutate();
  };

  return (
    <div className='w-full h-full text-white px-20'>
      <div className='text-2xl font-medium text-icon-gray'>
        <Link to={'../..'} relative='path'>
          Global Promos Config
        </Link>{' '}
        /{' '}
        <Link to={'../'} relative='path'>
          {globalPromotion?.title}
        </Link>{' '}
        / <span className='text-white'>Organizations</span>
      </div>

      <table className='w-full mt-12'>
        <thead>
          <tr className='text-left h-14'>
            <th>Organization</th>
            <th>Members</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {promotions?.map((p) => (
            <tr className='text-left h-14 text-sm font-normal' key={p.id}>
              <th className='font-normal'>{p.organization?.name ?? 'N/A'}</th>
              <th className='font-normal'>
                {p.organization?.organizersCount ?? 0}
              </th>
              <th>
                {p.status === PromotionStatus.Active ? 'Active' : 'Paused'}
              </th>
              <th className='font-normal'>
                <OrganizationActionSheet
                  promotion={p}
                  onPause={handlePause}
                  onResume={handleResume}
                />
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
