import { PromotionList } from '../components/GlobalPromotion/PromotionList';

function GlobalPromotionPromotionsRoute() {
  return <PromotionList />;
}

export const Component = GlobalPromotionPromotionsRoute;

export function clientLoader() {
  return null;
}
